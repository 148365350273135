



















































































import Vue from 'vue'
import FooterCopyright from './FooterCopyright.vue'
import FooterNavLinks from './FooterNavLinks.vue'

export default Vue.extend({
  components: { FooterNavLinks, FooterCopyright },
  name: 'Footer',

  data () {
    return {
      title: 'ARI.company is a Saas company specialized in service development and marketing. Since 2014, we’ve been helping clients worldwide create outstanding web services, mobile applications, and chatbots, offering an innovative approach and attention to detail.',
      servicesLinks: [
        { id: 1, title: 'SEO & ASO & ORM', link: '/' },
        { id: 2, title: 'Development', link: '/development' },
        { id: 3, title: 'Portfolio', link: '/portfolio' }
      ],
      companyLinks: [
        { id: 1, title: 'About Us', link: '/about-us' },
        { id: 2, title: 'Contact Us', link: '/contact' },
        { id: 3, title: 'Vacancies', link: '/vacancies' }
      ]
    }
  }
})
