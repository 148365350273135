import Vue from 'vue'
import VueRouter from 'vue-router'
import OptimizationPage from '../views/Optimization/OptimizationPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Optimization',
    component: OptimizationPage,
    meta: {
      layout: 'main-layout',
      title: 'SEO & ASO & ORM Optimization - Improve your rankings  - ARI.company',
      desc: 'We deliver effective SEO & ASO & ORM solutions to help businesses grow and increase their revenue. | ARI.company',
      keepAlive: true, //  Need to be cached
    }
  },
  {
    path: '/development',
    name: '',
    component: () => import(/* webpackChunkName: "optimization" */ '../views/Development/DevelopmentPage.vue'),
    meta: {
      layout: 'main-layout',
      title: 'SaaS Product & Software Development - ARI.company',
      desc: 'Our expertise covers all popular technologies and frameworks and advances to innovative technological trends.',
      keepAlive: true, //  Need to be cached
    }
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/Portfolio/PortfolioPage'),
    meta: {
      layout: 'main-layout',
      title: 'Our Portfolio - ARI.company',
      desc: 'Products We\'ve Built - ARI.company',
      keepAlive: true,
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact/ContactPage.vue'),
    meta: {
      layout: 'main-layout',
      title: 'Contact Us - ARI.company',
      desc: 'Tell us about your project!',
      keepAlive: true
    }
  },
  {
    path: '/about-us',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About/AboutPage'),
    meta: {
      layout: 'main-layout',
      title: 'We help businesses thrive - About Us - ARI.company',
      desc: 'To us, nothing is impossible. Due to our diverse customer base and variety of projects, we\'re ready to tackle any marketing or software development challenge. We\'re ready to bring your ideas to life!',
      keepAlive: true
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyPage'),
    meta: {
      layout: 'policy-layout',
      title: 'Privacy Policy - ARI.company',
      desc: 'We are committed to protecting your privacy.',
      keepAlive: true
    }
  },
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    component: () => import(/* webpackChunkName: "cookie" */ '../views/CookiePolicyPage'),
    meta: {
      layout: 'policy-layout',
      title: 'Cookie Policy -  ARI.company',
      desc: 'We are committed to providing a more user-friendly browsing experience and being transparent in the way we collect and process the user data when you access our website at https://www.ari.company/ website (hereinafter “the website”).',
      keepAlive: true
    }
  },
  {
    path: '/vacancies',
    name: 'Vacancies',
    component: () => import(/* webpackChunkName: "vacancies" */ '../views/Vacancies/VacanciesPage'),
    meta: {
      layout: 'policy-layout',
      title: 'We are hiring! Jobs - ARI.company',
      desc: 'Find your dream job and join our team!',
      keepAlive: true
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/404/NotFoundPage'),
    meta: {
      layout: 'policy-layout',
      title: '404 Page not Found! - ARI.company',
      desc: 'Oops! We couldn\'t find a page you were looking for. Maybe we can help?',
      keepAlive: true
    },
  },
  {
    path: '*',
    redirect: '/404'
  },

]

const scrollBehavior = function (to, from, savedPosition) {
  return { x: 0, y: 0 }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title === 'undefined' ? 'Ari Company' : to.meta.title
  let metas = document.getElementsByTagName("meta")

  metas.forEach(meta => {
    if (meta.name === 'description') {
      meta.content = to.meta.desc
    }
  })
  next()
})

export default router
